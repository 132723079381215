import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import Homepage from './pages/Homepage';
import BGIMAGE from './bg.png'


import './home.css'


const GlobalStyles = createGlobalStyle`
  body {
    // font-family: 'OpenSans';
    height: 100%;
    background: url('/bg.png'), url('/bg2.png');
    background-position: right bottom, left top;
    background-repeat: repeat, repeat;
    background-size: cover;
  }
`

function App() {
  return (
    <Router>
    <div>

    <GlobalStyles />

          <Switch>  
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>

    </div>
    </Router>
  );
}


ReactDOM.render(<App />, document.getElementById("root"));
