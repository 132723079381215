

import * as React from "react";
import * as ReactDOM from "react-dom";



import 'react-toastify/dist/ReactToastify.css';


function NavBar() {
 

  return (
<nav class="navbar navbar-expand-lg navbar-dark" style={{background:'linear-gradient(180deg, #144902 50%, #238001 50%)', minHeight:'80px'}}>
    

    <div style={{width:'100%', textAlign:'right'}}>

    <a className="d-none d-sm-inline" href="https://t.me/PresidentPepe24" target="_blank" rel="noreferrer">
    <img src="/buynow.png" alt="buy now" style={{width:'100%', maxWidth:'156px'}} />
    </a>
    <a href="https://t.me/PresidentPepe24" target="_blank" rel="noreferrer">
    <img src="/telegram.svg" width="40px" />
    </a>
    <a href="https://twitter.com/PresidentPepe24" target="_blank" rel="noreferrer">
    <img src="/twitter.svg" width="40px" />
    </a>

    </div>




</nav>


  ); 
}

export default NavBar;