import * as React from "react";
import * as ReactDOM from "react-dom";
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/Navbar'
import '../App.css'




function Homepage() {


 

  return (
    <div>
      <Navbar />

      <div className="row" style={{width:'100%'}}>
        <div className="col-12 col-md-6" style={{textAlign:'right', padding:"10px"}}>
            <a className="d-block d-sm-none" href="https://t.me/PresidentPepe24" target="_blank" rel="noreferrer" style={{textAlign:'center', width:'100%', marginTop:'-3rem', marginBottom:'8rem', position:'relative'}}>
          <img src="/buynow.png" alt="buy now" style={{width:'100%', maxWidth:'156px'}} />
          </a>
          <img src="/top.png" alt="top" style={{    width: "100%", marginTop:'-3rem', position:'relative', zIndex:'99'}} />
          <img className="d-block d-sm-none" src="/presidentpepe.png" alt="top" style={{ marginTop:'-8rem',   width: "100%",   }} />

          <p style={{color:'white', textAlign:'center', marginTop:"5rem", fontWeight:"bold", fontSize:'1.5rem', fontFamily:'AvenirBold'}}>
          The idea behind President Pepe is to poke fun at the 
political establishment and the idea that many politicians
 are out of touch with theconcerns of everyday people.
 In this story, Pepe is the only 
candidate who truly understands the issues that matter to
the electorate. Our ecosystem focuses on total internet 
freedom with the meme-loving, internet-savvy audience 
that President Pepe is meant to appeal to.
          </p>
        </div>
        <div className="col-12 col-md-6">
          <div style={{width:'100%'}}>
            <img src="/presidentpepe.png" className="d-none d-sm-inline" alt="top" style={{    width: "100%", maxWidth:'50rem', float:'right' }} />
          </div>
        </div>
      </div>
      

      <div className="container">
      <div className="row" style={{width:'100%'}}>
      <div className="col-12 d-block d-sm-none" style={{textAlign:'right', padding:"10px"}}>
          <img src="/pepeobamatext.png"  alt="top" style={{    width: "100%", position:'relative', zIndex:'99', marginTop:'2rem'}} />
        </div>
        <div className="col-12 col-md-6" style={{textAlign:'right', padding:"10px"}}>
          <img src="/pepeobama.png" alt="top" style={{    width: "100%", position:'relative', zIndex:'99'}} />
        </div>

        <div className="col-12 d-block d-sm-none" style={{textAlign:'right', padding:"10px"}}>
        <h2 style={{color:'white', fontWeight:'bold', fontFamily:'AvenirBold', textAlign:'center'}}>
          Just like how we had Dogecoin and then Shiba Inu, an ecosystem built around a meme, we believe PresidentPepe will be producing real utility amongst our comrade Pepe.
          </h2>        </div>

        <div className="col-12 col-md-6 d-none d-sm-inline" style={{textAlign:'right', padding:"10px"}}>
          <img src="/pepeobamatext.png"  alt="top" style={{    width: "100%", position:'relative', zIndex:'99', marginTop:'15rem'}} />
          <h2 style={{color:'white', fontWeight:'bold', fontFamily:'AvenirBold', textAlign:'center', marginTop:'2rem'}}>
          Just like how we had Dogecoin and then Shiba Inu, an ecosystem built around a meme, we believe PresidentPepe will be producing real utility amongst our comrade Pepe.
          </h2>
        </div>
      </div>

      <div className="row" style={{width:'100%'}}>
        <div className="col-12" style={{textAlign:'right', padding:"10px", marginTop:'5rem'}}>
          <img src="/tokenomics.png" alt="top" style={{    width: "100%", position:'relative', zIndex:'99'}} />
        </div>
        <div className="col-12">
          <p style={{textAlign:"center", fontWeight:'bold', color:'white', fontSize:'1.5rem', fontFamily:'AvenirBold'}}>
          420,696,969 Tokens
          </p>
          <p style={{textAlign:"center", fontWeight:'bold', color:'white', fontSize:'1.5rem', fontFamily:'AvenirBold'}}>
          Chain - <span style={{color:'green'}}>Ethereum</span>, to join and support our other successful Pepe’s.
          </p>
          <p style={{textAlign:"center", fontWeight:'bold', color:'white', fontSize:'1.5rem', fontFamily:'AvenirBold'}}>
          2% Tax in and out (4% round trip)
          </p>
          <p style={{textAlign:"center", fontWeight:'bold', color:'white', fontSize:'1.5rem', fontFamily:'AvenirBold'}}>
          Tax is allocated to Pepe’s campaign fund, which involves 
marketing, development and community rewards (Stimmies for holders!)
          </p>
        </div>
      </div>
   </div>

   <div className="row" style={{width:'100%', padding:"10px"}}>
        <div className="col-12 col-md-4" style={{textAlign:'left', padding:"10px", marginTop:'5rem'}}>
          <img src="/ppepe.png" alt="top" style={{    width: "100%", maxWidth:'500px', position:'absokute', left:'0', zIndex:'99'}} />
        </div>
        <div className="col-12 col-md-8" style={{textAlign:'left', padding:"10px", marginTop:'5rem'}}>
        <img src="/utilities.png" alt="top" style={{    width: "100%", maxWidth:'800px', position:'absokute', left:'0', zIndex:'99'}} />
        <p style={{color:'white', fontWeight:'bold', fontSize:'2rem', fontFamily:'AvenirBold'}}>
        A  <span style={{color:'#37ff00'}}>decentralised</span> social platform - We plan to build a decentralized social platform that allows users to 
interact with each other without fear of censorship or government interference. 
This platform could use blockchain technology to ensure
 that all user data is secure and private.
        </p>

        <p style={{color:'white', fontWeight:'bold', fontSize:'2rem', marginTop:'2.5rem', fontFamily:'AvenirBold'}}>
        A cryptocurrency to be used for political donations -<span style={{color:'#37ff00'}}>President Pepe's</span>  focus on challenging the political 
establishment could be used to build a cryptocurrency that enables supporters 
to donate to his campaign without fear of interference from traditional financial institutions. 
This cryptocurrency could be built on a decentralized blockchain network and 
could be used to fund other like-minded political campaigns in the future.
        </p>

        <p style={{color:'white', fontWeight:'bold', fontSize:'2rem', marginTop:'2.5rem', fontFamily:'AvenirBold'}}>
        A <span style={{color:'#37ff00'}}>Secure Voting System</span> - President Pepe's focus on internet freedom could 
be extended to create a secure and decentralized voting system that
 uses blockchain technology to ensure that all votes are counted accurately 
and without interference. This voting system could be used for 
political elections as well as other types of voting, such as on social 
platforms or for community decisions.
        </p>
        </div>
        </div>

        <div className="container">
          <div className="row" style={{width:'100%', padding:"10px"}}>
            <div className="col-12">
              <img src="/howtobuy.png" alt="howtobuy" style={{width:"100%", maxWidth:"550px", margin:'0 auto', display:'block'}} />
            </div>
            <div className="col-12">
              <h1 style={{textAlign:'center', fontSize:'2rem', color:'#238008', fontFamily:'AvenirBold'}}>
                Create a Wallet
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.5rem', color:'white', fontFamily:'AvenirBold'}}>           
                Download metamask or your wallet of choice from the app store or google play store for free. 
                Desktop users, download the google chrome extension by going to metamask.io.
              </h1>

              <h1 style={{textAlign:'center', fontSize:'2rem',  color:'#238008', fontFamily:'AvenirBold'}}>
              Get Some ETH
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.5rem',  color:'white', fontFamily:'AvenirBold'}}>           
               
Have ETH in your wallet to switch to President Pepe. 
If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, 
or buy on another exchange and send it to your wallet.
              </h1>

              <h1 style={{textAlign:'center', fontSize:'2rem',  color:'#238008', fontFamily:'AvenirBold'}}>
              Go to Uniswap
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.5rem',  color:'white', fontFamily:'AvenirBold'}}>           
              Connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. 
Connect your wallet. Paste the $PEPE token address into Uniswap, 
select Pepe, and confirm. When Metamask prompts you for a wallet signature, sign.
              </h1>

              <h1 style={{textAlign:'center', fontSize:'2rem',  color:'#238008', fontFamily:'AvenirBold'}}>
              Switch ETH for $PPepe
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.5rem',  color:'white', fontFamily:'AvenirBold'}}>           
              switch ETH for $PPEPE using our Contract Address. We Have a 2% tax so you must set your appliance to 3% to 
trade the token.
              </h1>

              <h1 style={{textAlign:'center', fontSize:'2rem',  color:'#238008', fontFamily:'AvenirBold'}}>
              HODL
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.5rem',  color:'white', fontFamily:'AvenirBold'}}>           
              HODL your stake in Pepe’s Campaign! Play your part in making Web3 Great Again.
              </h1>
            </div>
          </div>
        </div>

      <div className="container">
        <div className="row" style={{width:'100%', marginTop:'5rem'}}>
        <h1 style={{fontWeight:"bold", fontSize:'2rem',  color:'#238008', textAlign:'center', width:'100%', fontFamily:'AvenirBold'}}>
              DISCLAIMER:
              </h1>
              <h1 style={{textAlign:'center', fontSize:'1.2rem',  color:'white', fontFamily:'AvenirBold'}}>           
              President Pepe has no association with Matt Furie or his creation Pepe the Frog. 
This token simply pays homage to a meme we all love and recognize. 
              </h1>


              <h1 style={{textAlign:'center', fontSize:'1.2rem',  color:'#80ff5e', fontFamily:'AvenirBold'}}>           
              $PPepe is a meme coin with no intrinsic value 
or expectation of financial return. 
Only invest what you can afford to lose.
              </h1>
  
                <img src="/bottom.png" className="d-block d-sm-none" style={{width:'100%', margin:'0 auto', display:'block', zIndex:"-1"}} />
                <img src="/bottom.png" className="d-none d-sm-inline" style={{width:'100%', position:'absolute', right:'0', maxWidth:'400px', zIndex:"-1"}} />

        </div></div>
      </div>
  ); 
}

export default Homepage;